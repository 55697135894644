<template>
  <a-modal :destroy-onClose="true" :title="title" :visible="visible" :confirm-loading="confirmLoading"
           :maskClosable="false" @ok="handleOk" @cancel="handleCancel" :width="1200" v-drag-modal>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-model-item ref="title" label="通知标题" prop="title" v-bind="formItemLayout">
            <a-input v-model.trim="form.title" allow-clear placeholder="请输入通知标题"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="状态" v-bind="formItemLayout">
            <a-radio-group v-model="form.status">
              <a-radio class="tab-item" :value="1">有效</a-radio>
              <a-radio class="tab-item" :value="0">无效</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-model-item label="通知排序" v-bind="formItemLayout">
            <a-input-number v-model="form.sort" :step="10" allow-clear placeholder="输入排序序号" style="width: 150px"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="有效日期" v-bind="formItemLayout">
            <range-date @change="handleDateChange" ref="createTime" :default-start="validRange"></range-date>
          </a-form-model-item>
        </a-col>

      </a-row>
      <a-form-model-item label="通知内容" :label-col="{span: 3}" :wrapperCol="{span:19}">
        <tiny-mce-editor ref="editor" v-model="form.content" style="width: 100%"></tiny-mce-editor>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>


<script>
import TinyMceEditor from "@/components/Editor/TinyMceEditor";
import RangeDate from '@/components/datetime/RangeDate'


export default {
  name: "NoticeForm",
  components: {TinyMceEditor, RangeDate},
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      visible: false,
      confirmLoading: false,
      title: "新增系统通知",
      validRange: "",
      textMode: "",
      form: {
        sort: "",
        status: 1,
      },
      rules: {
        title: [
          {required: true, message: "请输入通知标题", trigger: "blur"},
        ],
        content: [
          {required: true, message: "通知内容不能为空", trigger: "blur"},
        ],
      },
    };
  },

  methods: {
    handleCancel(e) {
      this.visible = false;
      this.imageList = [];
      this.form = {
        status: 1,
      };
    },
    async showModal(mode = "new", record) {
      this.visible = true;
      this.textMode = mode;
      if (mode != "new") {
        this.title = "编辑通知";
        let res = await this.$get("notice/query", {id: record.id})
        record = res.data;
        this.validRange = [record.validFrom || "", record.validTo || ""]
        this.form = {
          ...record
        };
      }
    },

    handleDateChange(value) {
      if (value) {
        this.form.validFrom = value[0] + " 00:00:00"
        this.form.validTo = value[1] + " 00:00:00"
      } else {
        this.form.validFrom = ""
        this.form.validTo = ""
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        // console.log(this.form);
        // return

        if (valid) {
          if (this.form.content.indexOf("data:image/") >= 0) {
            this.$message.warn("图片请选择上传方式，不要直接剪切后粘贴图片！");
            return
          }
          this.confirmLoading = true;

          this.$postJson("notice/save", this.form)
              .then((res) => {
                this.confirmLoading = false;
                this.visible = false;
                this.$message.success("操作成功！");
                this.$emit("success", res.data);
              })
              .catch(() => {
                this.confirmLoading = false;
              });
        } else {
          // console.log(this);
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scope>
</style>
